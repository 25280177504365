import styled from 'styled-components'

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;

	width: 100%;
	padding: 32px 16px;

	background-image: url('/images/pattern2.png');

	${({ theme }) => theme.mediaQueries.xs} {
		background-size: auto;
	}

	${({ theme }) => theme.mediaQueries.lg} {
		background-size: 800px;
		min-height: calc(100vh - var(--havbar-height));
	}
`

export default Container
