import React, { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { ResetCSS } from './uikit-foodcourt'
import GlobalStyle from './style/Global'
import App from './pages/App'
import ApplicationUpdater from './state/application/updater'
import ListsUpdater from './state/lists/updater'
import MulticallUpdater from './state/multicall/updater'
import TransactionUpdater from './state/transactions/updater'
import ToastListener from './components/ToastListener'
import Providers from './Providers'
import 'inter-ui'
import './style/fontawesome-pro-5.15.3/css/all.min.css'
import './style/main.css'
import './i18n'

if ('ethereum' in window) {
	;(window.ethereum as any).autoRefreshOnNetworkChange = false
}

window.addEventListener('error', () => {
	localStorage?.removeItem('redux_localstorage_simple_lists')
})
;(window as any).$$data = {
	ROUTER_ADDRESS: process.env.REACT_APP_ROUTER_ADDRESS,
	REACT_APP_FACTORY_ADDRESS: process.env.REACT_APP_REACT_APP_FACTORY_ADDRESS,
	REACT_APP_INIT_CODE_HASH: process.env.REACT_APP_REACT_APP_INIT_CODE_HASH,
}

ReactDOM.render(
	<StrictMode>
		<Providers>
			<>
				<ListsUpdater />
				<ApplicationUpdater />
				<TransactionUpdater />
				<MulticallUpdater />
				<ToastListener />
			</>
			<ResetCSS />
			<GlobalStyle />
			<App />
		</Providers>
	</StrictMode>,
	document.getElementById('root'),
)
