import React from 'react'
import styled from 'styled-components'
import { CogIcon } from '../../../components/Svg'
import IconButton from '../../../components/Button/IconButton'
import { MENU_ENTRY_HEIGHT } from '../config'
import { PanelProps, PushedProps } from '../types'
import CakePrice from './CakePrice'
import ThemeSwitcher from './ThemeSwitcher'
import SocialLinks from './SocialLinks'
import LangSelector from './LangSelector'
import CouponPrice from '../../../components/CouponPrice'
import ChainSelectButton from '../../../components/ChainSelectButton'

interface Props extends PanelProps, PushedProps {}

const Container = styled.div`
	flex: none;
	padding: 8px 4px;
	background-color: ${({ theme }) => theme.nav.background};
	border-top: solid 2px rgba(133, 133, 133, 0.1);
`

const SettingsEntry = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: ${MENU_ENTRY_HEIGHT}px;
	padding: 0 8px;
`

const SocialEntry = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: ${MENU_ENTRY_HEIGHT}px;
	padding: 0 16px;
`

const PanelFooter: React.FC<Props> = ({
	isPushed,
	pushNav,
	toggleTheme,
	isDark,
	cakePriceUsd,
	currentLang,
	langs,
	setLang,
}) => (
	<Container>
		<SocialEntry>
			<div className="_dp-b _dp-n-md">
				<ChainSelectButton theme="primary" />
			</div>
			<div className="_dp-n _dp-b-md" />
			<SocialLinks />
		</SocialEntry>
		{process.env.REACT_APP_ENABLE_COUPON_UI === 'true' && (
			<div className="_dp-b _dp-n-md">
				<SocialEntry>
					{process.env.REACT_APP_ENABLE_COUPON_UI === 'true' && (
						<div className="_dp-b _dp-n-md _mgr-12px">
							<CouponPrice price={cakePriceUsd} theme="primary" />
						</div>
					)}
				</SocialEntry>
			</div>
		)}
		{/* <SettingsEntry> */}
		{/* <ThemeSwitcher isDark={isDark} toggleTheme={toggleTheme} />
				<LangSelector currentLang={currentLang} langs={langs} setLang={setLang} /> */}
		{/* </SettingsEntry> */}
	</Container>
)

export default PanelFooter
