import { useEffect } from 'react'
import useGetPriceData from './useGetPriceData'
import { COUPON } from '../constants'

const useGetDocumentTitlePrice = () => {
	const priceData = useGetPriceData()
	const cakePriceUsd = priceData ? parseFloat(priceData[COUPON[56].address].price) : 0

	const cakePriceUsdString =
		Number.isNaN(cakePriceUsd) || cakePriceUsd === 0
			? ''
			: ` - $${cakePriceUsd.toLocaleString(undefined, {
					minimumFractionDigits: 3,
					maximumFractionDigits: 3,
			  })}`

	useEffect(() => {
		// document.title = `Foodcourt${cakePriceUsdString}`
		document.title = `Foodcourt Finance`
	}, [cakePriceUsdString])
}
export default useGetDocumentTitlePrice
