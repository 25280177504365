import React from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import { SvgProps } from '../../../components/Svg'
import * as IconModule from '../icons'
import Accordion from './Accordion'
import { MenuEntry, LinkLabel, LinkStatus } from './MenuEntry'
import MenuLink from './MenuLink'
import { PanelProps, PushedProps } from '../types'
import classMap from 'classnames'

interface Props extends PanelProps, PushedProps {
	isMobile: boolean
}

const Icons = (IconModule as unknown) as { [key: string]: React.FC<SvgProps> }

const Container = styled.div`
	display: flex;
	flex-direction: column;
	overflow-y: auto;
	overflow-x: hidden;
	height: 100%;
	padding: 0 24px;
`

const MenuButton = styled.div``

const PanelBody: React.FC<Props> = ({ isPushed, pushNav, isMobile, links }) => {
	const location = useLocation()

	// Close the menu when a user clicks a link on mobile
	const handleClick = isMobile ? () => pushNav(false) : undefined

	return (
		<Container>
			{links.map((entry) => {
				const Icon = Icons[entry.icon]
				const iconElement = Icon ? <Icon width="24px" mr="8px" /> : <></>
				const calloutClass = entry.calloutClass ? entry.calloutClass : undefined

				if (entry.items) {
					const itemsMatchIndex = entry.items.findIndex((item) => item.href === location.pathname)
					const initialOpenState =
						entry.initialOpenState === true ? entry.initialOpenState : itemsMatchIndex >= 0

					return (
						<Accordion
							key={entry.label}
							isPushed={isPushed}
							pushNav={pushNav}
							icon={iconElement}
							label={entry.label}
							status={entry.status}
							initialOpenState={initialOpenState}
							className={calloutClass}
							isActive={entry.items.some((item) => item.href === location.pathname)}
						>
							{isPushed &&
								entry.items.map((item) => (
									<MenuEntry
										key={item.href}
										secondary
										isActive={item.href === location.pathname}
										onClick={handleClick}
									>
										<MenuLink href={item.href}>
											<LinkLabel isPushed={isPushed}>{item.label}</LinkLabel>
											{item.status && (
												<LinkStatus color={item.status.color} fontSize="14px">
													{item.status.text}
												</LinkStatus>
											)}
										</MenuLink>
									</MenuEntry>
								))}
						</Accordion>
					)
				}

				return (
					<MenuButton
						key={entry.label}
						className={classMap('sidenav-menu _mgt-16px _mnh-48px', {
							'-active': entry.href === location.pathname,
						})}
					>
						<MenuLink
							href={entry.href}
							onClick={handleClick}
							className={classMap('_h-48px _mnh-48px _dp-f _alit-ct _jtfct-ct _w-100pct')}
						>
							<div className="lo-12 _gg-2px">
								<div>{entry.label}</div>
								{entry.subtitle && <div className="_fw-300 _fs-100">{entry.subtitle}</div>}
							</div>
						</MenuLink>

						{/* NOTE: Spoon menu image */}
						<svg
							className="left"
							width="18px"
							height="67px"
							viewBox="0 0 18 67"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
								<path
									d="M17.12,64.218 C16.859,66.998 13.111,67.622 12.227,64.424 C8.966,54.676 8.15,29.323 6.975,19.649 C4.337,18.574 -0.476,12.731 0.967,6.79 C2.291,-0.281 6.682,-0.032 8.863,0.881 C15.05,3.467 14.044,14.727 10.185,19.377 C9.908,19.71 18.267,52.032 17.12,64.218"
									id="Fill-9709"
									fill="var(--color-accent-500)"
								/>
							</g>
						</svg>

						{/* NOTE: Fork menu image */}
						<svg
							className="right"
							width="17px"
							height="63px"
							viewBox="0 0 17 63"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
								<path
									d="M11.803,14.226 C11.85,14.789 12.612,14.925 12.834,14.406 C15.283,8.64 18.201,-8.937 15.775,15.209 C15.388,19.048 12.824,19.511 10.916,20.195 C10.101,20.487 7.65,48.341 7.296,51.625 C6.041,63.269 2.482,63.873 1.337,61.801 C-1.535,56.607 4.112,38.58 7.971,19.786 C2.479,16.899 2.858,16.945 8.621,1.883 C10.674,-3.483 7.557,12.459 7.67,13.2 C8.264,17.104 11.601,4.876 13.083,2.061 C13.559,4.919 11.587,11.619 11.803,14.226"
									id="Fill-9712"
									fill="var(--color-accent-500)"
								/>
							</g>
						</svg>
					</MenuButton>
				)
			})}

			<a href="https://app.inspex.co/library/foodcourt#?scope=foodcourt-amm-farm-wrappedtokens" target="_blank" rel="noreferrer">
				<div className="_fw-600 _mgt-16px _bdrd-max lo-12 _alit-ct _jtfit-ct _mgbt-12px">
					<div className="_mgr-12px-md _mgbt-8px _dp-f _alit-ct">
						<div className="_w-12px _h-12px _bgcl-positive-500 _bdrd-max _mgr-4px" style={{ boxShadow: '1px 1px 0px white, 2px 2px 0px #F7D058, 3px 3px 0px #31C155' }} />
						<div className="_cl-accent-500 _fs-300-md _fs-300" style={{ color: 'var(--color-accent-500)', textShadow: '1px 1px 0px white, 2px 2px 0px #F7D058, 3px 3px 0px #31C155' }}>audited by</div>
					</div>
					<div className="_pd-12px _bdrd-8px" style={{ backgroundColor: '#051026' }}>
						<img src="/images/inspex.png" className="_h-24px" alt="inspex.png" />
					</div>
				</div>
			</a>

			<a href="https://killswitch.finance/" target="_blank" rel="noreferrer">
				<div className="_fw-600 _mgt-16px _bdrd-max lo-12 _alit-ct _jtfit-ct _mgbt-12px">
					<div className="_mgr-12px-md _mgbt-8px _dp-f _alit-ct">
						<div className="_w-12px _h-12px _bgcl-positive-500 _bdrd-max _mgr-4px" style={{ boxShadow: '1px 1px 0px white, 2px 2px 0px #F7D058, 3px 3px 0px #31C155' }} />
						<div className="_cl-accent-500 _fs-300-md _fs-300" style={{ color: 'var(--color-accent-500)', textShadow: '1px 1px 0px white, 2px 2px 0px #F7D058, 3px 3px 0px #31C155' }}>Fullnode service by</div>
					</div>
					<div className="_pd-12px _bdrd-8px">
						<img src="/images/ksw.svg" className="_h-32px" alt="killswitch.png" />
					</div>
				</div>
			</a>
		</Container>
	)
}

export default PanelBody
