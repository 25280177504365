import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { Button, Text } from '../../uikit-foodcourt'
import { AlertTriangle } from 'react-feather'
import Modal from '../Modal'
import { AutoRow, RowBetween } from '../Row'
import { AutoColumn } from '../Column'

const WarningContainer = styled.div`
	max-width: 420px;
	width: 100%;
	padding: 1rem;
	background: rgba(242, 150, 2, 0.05);
	border: 1px solid #f3841e;
	border-radius: 20px;
	overflow: auto;
`

const StyledWarningIcon = styled(AlertTriangle)`
	stroke: ${({ theme }) => theme.colors.binance};
`

export default function RSafeWarningModal({
	isOpen,
	onConfirm,
}: {
	isOpen: boolean
	onConfirm: () => void
}) {
	const [understandChecked, setUnderstandChecked] = useState(false)
	const toggleUnderstand = useCallback(() => setUnderstandChecked((uc) => !uc), [])

	const handleDismiss = useCallback(() => null, [])
	return (
		<Modal isOpen={isOpen} onDismiss={handleDismiss} maxHeight={90}>
			<WarningContainer className="token-warning-container">
				<AutoColumn gap="lg">
					<AutoRow gap="6px">
						<StyledWarningIcon />
						<Text>Notice for rToken</Text>
					</AutoRow>
					<>
						{/* <div style={{ marginBottom: 12 }}>
							<Text>
								rMMP ถูกสร้างขึ้นมาเพื่อใช้ในการ Farm ข้างเดียว ใน foodcourt.finance
								เท่านั้น
							</Text>
							<Text>
								<u>
									<b>ห้าม</b>
								</u>{' '}
								ทำการซื้อ ขาย หรือเสริม Liquidity ให้กับเหรียญ rMMP
								ไม่ว่ากรณีใดทั้งสิ้น
							</Text>
							<Text>
								<u>
									<b>ระวัง</b>
								</u>{' '}
								rMMP เป็นเหรียญพิเศษที่ใช้เพื่อ Stake เพื่อรับ Reward เป็น COUPON
								เท่านั้น เมื่อ Stake แล้ว จะไม่สามารถถอนได้
							</Text>
							<Text>
								<u>
									<b>โปรโมชั่น</b>
								</u>{' '}
								ในช่วงระยะเวลาโปรโมชั่น จะสามารถนำ rMMP มา Stake เพื่อรับ wMMP
								หลังจากหมดระยะเวลาโปรโมชั่น สามารถถอน rMMP และนำ rMMP มา Stake
								เพื่อรับ COUPON ได้
							</Text>
						</div> */}

						<div style={{ marginBottom: 12 }}>
							<Text>
								rToken will receive for free when you wrap deflationary token(MMP, SAFEMOON, SAFEMARS and SAFEPAWS) from foodcourt. We no longer use rToken within our platform, you can just leave in your wallet.
							</Text>
						</div>
					</>
					<RowBetween>
						<div>
							<label
								htmlFor="understand-checkbox"
								style={{ cursor: 'pointer', userSelect: 'none' }}
							>
								<input
									id="understand-safeMoonWarning"
									type="checkbox"
									className="understand-checkbox"
									checked={understandChecked}
									onChange={toggleUnderstand}
								/>{' '}
								<Text as="span">I understand</Text>
							</label>
						</div>
						<Button
							id="confirm-safeMoonWarning"
							disabled={!understandChecked}
							variant="danger"
							style={{ width: '140px' }}
							onClick={() => {
								setUnderstandChecked(false)
								onConfirm()
							}}
						>
							Continue
						</Button>
					</RowBetween>
				</AutoColumn>
			</WarningContainer>
		</Modal>
	)
}
