import React, { useCallback, useMemo } from 'react'
import { diffTokenLists, TokenList } from '@uniswap/token-lists'
import { Button, Text } from '../../uikit-foodcourt'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../state'
import { useRemovePopup } from '../../state/application/hooks'
import { acceptListUpdate } from '../../state/lists/actions'
import listVersionLabel from '../../utils/listVersionLabel'
import { AutoColumn } from '../Column'
import { AutoRow } from '../Row'

export default function ListUpdatePopup({
	popKey,
	listUrl,
	oldList,
	newList,
	auto,
}: {
	popKey: string
	listUrl: string
	oldList: TokenList
	newList: TokenList
	auto: boolean
}) {
	const removePopup = useRemovePopup()
	const removeThisPopup = useCallback(() => removePopup(popKey), [popKey, removePopup])
	const dispatch = useDispatch<AppDispatch>()

	const handleAcceptUpdate = useCallback(() => {
		if (auto) return
		dispatch(acceptListUpdate(listUrl))
		removeThisPopup()
	}, [auto, dispatch, listUrl, removeThisPopup])

	const { added: tokensAdded, changed: tokensChanged, removed: tokensRemoved } = useMemo(
		() => diffTokenLists(oldList.tokens, newList.tokens),
		[newList.tokens, oldList.tokens],
	)
	const numTokensChanged = useMemo(
		() =>
			Object.keys(tokensChanged).reduce(
				(memo, chainId: any) => memo + Object.keys(tokensChanged[chainId]).length,
				0,
			),
		[tokensChanged],
	)

	return (
		<AutoRow>
			<AutoColumn style={{ flex: '1' }} gap="8px">
				{auto ? (
					<Text>
						The token list &quot;{oldList.name}&quot; has been updated to{' '}
						<strong>{listVersionLabel(newList.version)}</strong>.
					</Text>
				) : (
					<>
						<div>
							<Text fontSize="14px">
								An update is available for the token list &quot;{oldList.name}&quot;
								({listVersionLabel(oldList.version)} to{' '}
								{listVersionLabel(newList.version)}).
							</Text>
							<ul>
								{tokensAdded.length > 0 ? (
									<li>
										{tokensAdded.map((token, i) => (
											<React.Fragment
												key={`${token.chainId}-${token.address}`}
											>
												<strong title={token.address}>
													{token.symbol}
												</strong>
												{i === tokensAdded.length - 1 ? null : ', '}
											</React.Fragment>
										))}{' '}
										added
									</li>
								) : null}
								{tokensRemoved.length > 0 ? (
									<li>
										{tokensRemoved.map((token, i) => (
											<React.Fragment
												key={`${token.chainId}-${token.address}`}
											>
												<strong title={token.address}>
													{token.symbol}
												</strong>
												{i === tokensRemoved.length - 1 ? null : ', '}
											</React.Fragment>
										))}{' '}
										removed
									</li>
								) : null}
								{numTokensChanged > 0 ? (
									<li>{numTokensChanged} tokens updated</li>
								) : null}
							</ul>
						</div>
						<AutoRow>
							<div style={{ flexGrow: 1, marginRight: 12 }}>
								<Button onClick={handleAcceptUpdate}>Accept update</Button>
							</div>
							<div style={{ flexGrow: 1 }}>
								<Button onClick={removeThisPopup}>Dismiss</Button>
							</div>
						</AutoRow>
					</>
				)}
			</AutoColumn>
		</AutoRow>
	)
}
